<template>
  <base-page>
    <titlu-pagina Titlu="Dashboard" :AdaugaVisible="false"></titlu-pagina>

    <el-card style="margin: 5px 0px 5px 0px">
      <div slot="header" class="clearfix">
        <strong>Dashboard</strong>
      </div>
      <div>
        <p>Welcome {{ email }}</p>
        <el-button
          icon="el-icon-service"
          round
          @click="$router.push('app/tables')"
          >App</el-button
        >

        <el-button icon="el-icon-files" round @click="$router.push('mese')"
          >Tabels</el-button
        >
        <el-button icon="el-icon-menu" round @click="$router.push('category')"
          >Category</el-button
        >
        <el-button icon="el-icon-food" round @click="$router.push('orders')"
          >Orders</el-button
        >
        <el-button icon="el-icon-food" round @click="$router.push('products')"
          >Products</el-button
        >
        <el-button
          icon="el-icon-circle-plus-outline"
          round
          @click="$router.push('extra')"
          >Extra</el-button
        >
        <el-button
          icon="el-icon-circle-plus-outline"
          round
          @click="$router.push('methods_of_preparation')"
          >Methods of preparation</el-button
        >
        <el-button
          icon="el-icon-setting"
          round
          @click="$router.push('settings')"
          >Settings</el-button
        >
      </div>
    </el-card>
  </base-page>
</template>

<script>
import settings from "../backend/LocalSettings";
import BasePage from "../pages/BasePage";
import TitluPagina from "../widgets/TitluPagina";

export default {
  name: "dashboard",
  extends: BasePage,
  components: {
    "base-page": BasePage,
    "titlu-pagina": TitluPagina,
  },
  data() {
    return {
      email: settings.get_email(),
    };
  },
  methods: {
    test() {
      return settings.get_email();
    },
    async get_info() {
      this.refresh_info();
    },

    async refresh_info() {
      this.select_menu_item("dashboard");
    },
  },
  mounted() {
    this.get_info();
  },
};
</script>

<style lang="less" scoped>
.top50 {
  margin-top: 20px;
}
</style>
